import moment from 'moment';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { usePatientStore } from '@/stores/patients';

import { Patient } from '../types';

export type AddPatientResponse = {
  success?: string;
  error?: string;
  clinic_users?: Patient[];
  new_clinic_user_id: number;
};

export type AddPatientDTO = {
  data: any;
};

export const addPatient = ({ data }: AddPatientDTO): Promise<AddPatientResponse> => {
  console.log('addPatient', {
    ...data,
    dob: moment(data.dob, 'YYYY-MM-DD').unix(),
  });
  return axios.post('/vue_api/add_patient', {
    ...data,
    dob: moment(data.dob, 'YYYY-MM-DD').unix(),
  });
};

type UseAddPatientOptions = {
  config?: MutationConfig<typeof addPatient>;
};

export const useAddPatient = ({ config }: UseAddPatientOptions = {}) => {
  const { addNotification } = useNotificationStore();
  const { setPatients } = usePatientStore();

  console.log('useAddPatient');

  return useMutation({
    // onMutate: async (newPatient) => {
    //   console.log('onMutate');
    //   return;
    // },
    onError: (_, __, context: any) => {
      console.log('onError');
      addNotification({
        type: 'error',
        title: 'There was a problem trying to add the new Patient',
      });
    },
    onSuccess: (data, vars) => {
      console.log('onSuccess');
      // queryClient.invalidateQueries(['patients', vars.clinic_user_id]);
      if (data.success && data.clinic_users) {
        setPatients(data.clinic_users);
      }
      addNotification({
        type: 'success',
        title: 'A new Patient has been added',
      });
    },
    ...config,
    mutationFn: addPatient,
  });
};
